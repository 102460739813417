.nds-modal {
  .nds-modal-overlay {
    display: none;
    z-index: 1;
    background: #000;
    opacity: 70%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  & > .nds-modal-container {
    overflow: auto;
    max-height: 80vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    display: none;
    z-index: 100;
    position: fixed;
    background: RGB(var(--nds-white));
    max-width: 500px;
    border-radius: 0;
  }
  &.center {
    border-radius: 8px;
  }
  @media (min-width: $desktop-small) {
    &.center > .nds-modal-container {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    }
  }

  &[data-open="true"] {
    > .nds-modal-overlay {
      display: block;
    }
    > .nds-modal-container {
      display: block;
      > .nds-modal-dismiss {
        display: block;
      }
    }
  }

  .nds-modal-dismiss {
    display: none;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  /* non-center styles */
  &:not(.center) {
    & > .nds-modal-container {
      top: 0;
      right: 0;
      transform: none;
      overflow: initial;
    }
  }

  /* right styles */
  &.right {
    @media (min-width: $desktop-small) {
      height: 40px;
    }
    & > .nds-modal-container {
      bottom: 0;
      max-height: 100vh;
      left: initial;
      padding: 40px 25px 40px 20px;
      border-radius: 0;
      width: 335px;
      @media (min-width: $desktop-small) {
        width: initial;
      }
    }
  }
  /* details styles */
  &.details[data-open="true"] > .nds-modal-overlay {
    display: none;
  }
  &.details[data-open="true"] > .nds-modal-container > .nds-modal-dismiss {
    display: none;
  }
  &.details > .nds-modal-container {
    position: static;
    padding: 0;
    background: none;
    & > .nds-details-container {
      background: RGB(var(--nds-white));
    }

    display: block;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
  }

  &.details[data-open="true"] > .nds-modal-container {
    max-height: 80vh;
    overflow-y: auto;
  }

  .nds-modal-action-row {
    display: flex;
    flex-direction: row;
    float: right;
    align-items: center;
    margin-top: 40px;
  }

  h4.nds-sans {
    margin-top: 0;
    margin-bottom: 0;
  }
}

hr.nds-hr {
  border: 1px solid var(--theme-primary);
}
