@import "../../scss/mixins";

.base-banner {
  display: flex;
  padding: 20px 0;

  .banner-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    align-content: center;

    .banner-icon {
      @include visuallyHide;
      &:focus-within {
        @include visuallyShow;
      }
    }

    &:hover {
      .banner-icon {
        @include visuallyShow;
      }
    }

    .banner-title {
      font-size: var(--font-size-heading3);
    }

    .banner-chevron {
      display: flex;
      align-items: center;
      color: var(--color-black);
      margin-left: -16px;
      margin-top: 2px;
    }

    .banner-button {
      margin-left: auto;
    }

    [class^="narmi-icon-"] {
      margin-right: var(--space-xs);
      transform: translate(0, -1px);
    }
  }
}

.edit-modal-action-bar {
  display: flex;
  justify-content: flex-end;
}

.banner-action-button-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-at-m {
  display: initial;
}

.display-at-m {
  display: none;
}

@include minViewport("l") {
  .hide-at-m {
    display: none;
  }

  .display-at-m {
    display: initial;
  }

  .base-banner {
    min-height: 64px;
    padding: 0;

    .banner-content {
      .banner-hide-icon {
        display: none;
      }

      .banner-title {
        letter-spacing: 0.5px;
        line-height: 40px;
        padding-bottom: 0.1em; // to allow descenders to be centered
        font-size: var(--font-size-heading1);
      }

      [class^="narmi-icon-"] {
        color: var(--color-black);
      }
    }

    .banner-chevron {
      left: -51px;
      margin-left: 0;
      margin-top: 0;
      width: 62px;
      position: absolute;
    }

    &::before {
      content: " ";
      position: absolute;
      background-color: RGBA(
        var(--theme-rgb-primary),
        var(--ephemeral-opacity)
      );
      min-height: 64px;
      left: -100vw;
      right: 0;
      width: 200vw;
    }
  }
}
