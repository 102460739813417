@import "../../../scss/mixins";

.dashboard-section-container {
  .dashboard-item-border-box {
    border-top: 1px solid RGB(var(--border-color));
  }
}

.account-manager-options {
  display: flex;
  flex-direction: row;
  color: var(--color-mediumGrey);
  margin-top: var(--space-xxs);
  * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

[data-classname="AppAuthorize services-button"] {
  width: 100%;
}

.dashboard-item-border-box:first-of-type {
  border-top: none;
  [data-classname="AppAuthorize services-button"] {
    > .nds-button-content:first-of-type {
      margin-top: 0;
    }
  }
}

.dashboard-item-border-box:last-of-type {
  [data-classname="AppAuthorize services-button"] {
    > .nds-button-content:last-of-type {
      margin-bottom: -12px;
    }
  }
}

.dashboard-item-border-box {
  [data-classname="AppAuthorize services-button"] {
    > .nds-button-content {
      margin: 0;
      font-size: var(--font-size-default);
    }
    margin-left: 0;
  }
}

.dashboard-item-text-section {
  color: RGB(var(--nds-black));
}

.dashboard-item-name {
  margin-bottom: 2.5px;
  font-weight: var(--font-weight-semibold);
}

.dashboard-item-title-section {
  color: RGBA(var(--theme-rgb-primary));
  font-size: 20px;
  font-weight: 700;
}

.dashboard-item-description {
  color: RGBA(var(--nds-grey));
  font-weight: var(--font-weight-normal);
}

.dashboard-item-subsection {
  display: flex;
  place-content: center;
  flex-direction: column;
  min-width: 30px;
}

.dashboard-item-icon-section {
  color: RGBA(var(--theme-rgb-primary));

  $icon-width: 24px;
  $gap-between-icon-and-text-width: var(--space-l);

  padding-right: $gap-between-icon-and-text-width;
  flex: 0 0 calc($icon-width + $gap-between-icon-and-text-width);

  span {
    font-size: $icon-width;
  }
}

.promotion-icon-section {
  padding-right: 9px;
  color: RGBA(var(--theme-rgb-primary));
}

.dashboard-section-card {
  padding-bottom: 8px;
}

.service-card {
  > .nds-row {
    margin-bottom: 8px;
  }
}

.dashboard-item-icon-section:last-of-type {
  padding-bottom: 0;
}

.dashboard-item {
  display: flex;
  padding: 12px 10px 12px 0;
}

.promotion-item {
  padding-top: 0 !important;
}

.services-title {
  margin-bottom: 8px;
}

.available-balance-header {
  color: RGBA(var(--theme-rgb-primary));
}

.hidden-total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  hr {
    margin-top: 10px;
    margin-bottom: 12px;
    border-color: var(--color-lightGrey);
  }

  label span {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-m);
  }
}

.dashboard-page-container {
  .brand-hover:hover {
    background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
    cursor: pointer;
  }

  .account-group-header {
    &:not(:first-of-type) {
      padding-top: 16px !important;
    }
    .group-name {
      display: flex;
      flex-direction: row;
      color: RGBA(var(--primary-accessible-color));
      span.narmi-icon-info {
        margin-top: 2px; // to force a visual center alignment
      }
      * {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .account-rows {
    .account-row {
      border-top: 1px solid RGBA(var(--nds-lightest-grey));
    }
  }

  .account-group-header,
  .account-row {
    .options-wrapper {
      width: 16px; // always make space for the options trigger
    }

    .options-trigger {
      @include visuallyHide;
    }

    &:hover .options-trigger,
    .options--open .options-trigger,
    .options .options-trigger:focus {
      @include visuallyShow;
    }

    .options-trigger:focus,
    .options-trigger--open {
      border-radius: var(--border-radius-s);
      background-color: rgba(
        var(--theme-rgb-primary),
        var(--ephemeral-opacity)
      );
    }
  }

  .dashboard-sidebar-card-image {
    margin-top: 24px;

    @include minViewport("l") {
      margin-top: var(--space-xl);
    }
  }
}

.option-title {
  padding: 8px 0 4px 35px;
  font-weight: var(--font-weight-bold);
  color: RGBA(var(--nds-medium-grey));
  font-size: var(--font-size-xs);
}

.balance-options {
  display: flex;
  flex-direction: row;
  * {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-grey);
  }
}

.popover-option {
  width: "201px";

  .dropdown-list-item {
    white-space: nowrap;
    width: 100%;
    justify-content: flex-start;
    padding: 6px 11px 6px 8px;
    font-size: var(--font-size-s);
  }
  .pre-option-checkmark {
    width: max-content;
  }
  .dropdown-list-item-content {
    width: max-content;
  }
}

.account-options {
  font-size: var(--font-size-s);
}
